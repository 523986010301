import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { delayRequest } from '../../shared/util/delayed-request.util';
import { ChangeRequest } from '../interfaces/change-request.interface';
import {
  PersonalInformation,
  PersonalInformationCreate,
  PersonalInformationUpdate,
} from '../interfaces/personal-information.interface';
import { ProofOfLife } from '../interfaces/proof-of-life.interface';
import { uploadSupportingDocumentation } from '../util/supporting-docs-upload';
import { UpsertChangeRequests } from './change-request.state';

export interface PersonalInformationStateModel extends BaseStateModel {
  personalInformation: PersonalInformation;
  proofOfLife: ProofOfLife;
}

export class LoadPersonalInformation {
  static readonly type = '[PersonalInformation] LoadPersonalInformation';
}

export class UpdatePersonalInformation {
  static readonly type = '[PersonalInformation] UpdatePersonalInformation';
  constructor(
    public personalInformation: PersonalInformationUpdate,
    public files: File[] = [],
  ) {}
}

export class CreatePersonalInformation {
  static readonly type = '[PersonalInformation] CreatePersonalInformation';
  constructor(
    public personalInformation: PersonalInformationCreate,
    public files: File[] = [],
  ) {}
}

export class UpdateProofOfLife {
  static readonly type = '[ProofOfLife] UpdateProofOfLife';
  constructor(
    public proofOfLife: ProofOfLife,
    public files: File[] = [],
  ) {}
}

export class CreateProofOfLife {
  static readonly type = '[ProofOfLife] CreateProofOfLife';
  constructor(
    public proofOfLife: ProofOfLife,
    public files: File[] = [],
  ) {}
}

@State<PersonalInformationStateModel>({
  name: 'personalInformation',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    personalInformation: {
      id: '',
      personalEmail: '',
      countryCode: '',
      areaCode: '',
      phone: '',
      maritalStatus: 'SINGLE',
    },
    proofOfLife: {
      id: '',
      year: '',
    },
  },
})
@Injectable()
export class PersonalInformationState {
  http = inject(HttpClient);

  @Selector()
  static loading(state: PersonalInformationStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: PersonalInformationStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: PersonalInformationStateModel) {
    return state.error;
  }

  @Selector()
  static personalInformation(state: PersonalInformationStateModel) {
    return state.personalInformation;
  }

  @Action(LoadPersonalInformation)
  async loadPaymentInformation(
    ctx: StateContext<PersonalInformationStateModel>,
  ) {
    ctx.patchState({ loading: true });
    try {
      const personalInformation = await lastValueFrom(
        this.http.get<PersonalInformation>(
          `${environment.apiUrl}/v1/personal-information`,
        ),
      );
      ctx.patchState({ personalInformation });
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(UpdatePersonalInformation)
  async updatePersonalInformation(
    ctx: StateContext<PersonalInformationStateModel>,
    action: UpdatePersonalInformation,
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await lastValueFrom(
        this.http.put<ChangeRequest>(
          `${environment.apiUrl}/v1/personal-information`,
          action.personalInformation,
        ),
      );

      await uploadSupportingDocumentation(action, changeRequest, this.http);

      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(CreatePersonalInformation)
  async createPersonalInformation(
    ctx: StateContext<PersonalInformationStateModel>,
    action: CreatePersonalInformation,
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await delayRequest(
        lastValueFrom(
          this.http.post<ChangeRequest>(
            `${environment.apiUrl}/v1/personal-information`,
            action.personalInformation,
          ),
        ),
      );

      await uploadSupportingDocumentation(action, changeRequest, this.http);

      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(UpdateProofOfLife)
  async updateProofOfLife(
    ctx: StateContext<PersonalInformationStateModel>,
    action: UpdateProofOfLife,
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await lastValueFrom(
        this.http.put<ChangeRequest>(
          `${environment.apiUrl}/v1/personal-information`,
          action.proofOfLife,
        ),
      );

      await uploadSupportingDocumentation(action, changeRequest, this.http);

      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(CreateProofOfLife)
  async createProofOfLife(
    ctx: StateContext<PersonalInformationStateModel>,
    action: CreateProofOfLife,
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await delayRequest(
        lastValueFrom(
          this.http.post<ChangeRequest>(
            `${environment.apiUrl}/v1/personal-information`,
            action.proofOfLife,
          ),
        ),
      );

      await uploadSupportingDocumentation(action, changeRequest, this.http);

      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }
}
