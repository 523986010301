import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BehaviorSubject, firstValueFrom, map } from 'rxjs';
import { externalUrls } from '../../../../environments/constants';
import { matches } from '../../../shared/form-validators/matches.validator';
import { passwordValidator } from '../../../shared/form-validators/password.validator';
import {
  AuthState,
  CheckIfUserAlreadyExistsForInvite,
  Register,
} from '../../auth.state';

@UntilDestroy()
@Component({
  selector: 'app-register',
  template: `
    <app-login-screen>
      @if (userAlreadyExistsSentNewInvite) {
        <app-login-intro>
          <ng-container slot="title">Registration link expired</ng-container>
          <ng-container slot="subtitle">
            A new invite has been sent to your email address.
          </ng-container>
        </app-login-intro>
        <div>
          <p>
            Please check your email and follow the instructions to complete your
            registration.
          </p>
        </div>
      }

      @if (!userAlreadyExistsSentNewInvite) {
        <app-login-intro>
          <ng-container slot="title">Let's get you started!</ng-container>
          <ng-container slot="subtitle">
            Enter your new login credentials below
          </ng-container>
        </app-login-intro>
        @if (!hasRegistered) {
          <form
            [formGroup]="registerForm"
            (ngSubmit)="register()"
            class="space-y-4"
          >
            <mat-form-field>
              <mat-label>{{ 'LOGIN.EMAIL' | translate }}</mat-label>
              <input type="email" disabled matInput [value]="email$ | async" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input
                id="name"
                type="text"
                matInput
                [formControl]="registerForm.controls['contactName']"
                placeholder="E.g. John Doe"
              />
              @if (registerForm.controls['contactName'].hasError('required')) {
                <mat-error>This field is required</mat-error>
              }
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'LOGIN.PASSWORD' | translate }}</mat-label>
              <input
                id="password"
                type="password"
                [maxlength]="72"
                matInput
                [formControl]="registerForm.controls['password']"
              />
              @if (registerForm.controls['password'].hasError('required')) {
                <mat-error>
                  {{ 'LOGIN.PASSWORD_REQUIRED' | translate }}
                </mat-error>
              }
            </mat-form-field>
            <app-password-strength
              [zxcvbn]="registerForm.controls['password'].getError('zxcvbn')"
              [password]="registerForm.controls['password'].value"
            ></app-password-strength>
            <mat-form-field>
              <mat-label>{{ 'LOGIN.CONFIRM_PASSWORD' | translate }}</mat-label>
              <input
                id="confirm-password"
                type="password"
                [maxlength]="72"
                matInput
                [formControl]="registerForm.controls['passwordRepeat']"
              />
              @if (registerForm.controls['password'].hasError('required')) {
                <mat-error>
                  {{ 'LOGIN.PASSWORD_REQUIRED' | translate }}
                </mat-error>
              }
            </mat-form-field>
            <mat-checkbox
              [formControl]="registerForm.controls['tos']"
              id="tos-checkbox"
            >
              I have read and agree to the
              <button class="underline" (click)="showDisclaimer()">
                disclaimer
              </button>
            </mat-checkbox>
            <mat-checkbox
              [formControl]="registerForm.controls['privacy']"
              id="privacy-checkbox"
            >
              I have read and agree to the
              <button class="underline" (click)="showPrivacyPolicy()">
                privacy policy
              </button>
            </mat-checkbox>
            @if (
              registerForm.touched &&
              registerForm.dirty &&
              (registerForm.controls['tos'].hasError('required') ||
                registerForm.controls['tos'].hasError('requiredTrue') ||
                registerForm.controls['privacy'].hasError('required') ||
                registerForm.controls['privacy'].hasError('requiredTrue'))
            ) {
              <app-error class="mt-4">
                Please agree to the License Agreements
              </app-error>
            }
            <app-api-error [error]="authError$ | async"></app-api-error>
            @if (
              registerForm.touched &&
              registerForm.dirty &&
              registerForm.hasError('matches')
            ) {
              <app-error>
                {{ 'LOGIN.PASSWORDS_DO_NOT_MATCH' | translate }}
              </app-error>
            }
            <div class="flex items-center justify-end">
              <button
                id="register-button"
                mat-flat-button
                color="primary"
                type="submit"
                [disabled]="registering$ | async"
              >
                <span>
                  {{ 'LOGIN.REGISTER' | translate }}
                </span>
                @if (registering$ | async) {
                  <app-loading-spinner></app-loading-spinner>
                }
              </button>
            </div>
          </form>
        }
        @if (hasRegistered) {
          <div>
            <p class="pb-4">
              {{ 'LOGIN.REGISTRATION_SUCCESS' | translate }}
            </p>
            <a
              mat-flat-button
              color="primary"
              [routerLink]="['/auth/login']"
              class="w-full"
            >
              {{ 'LOGIN.SIGN_IN' | translate }}
            </a>
          </div>
        }
      }
    </app-login-screen>
  `,
})
export class RegisterComponent implements OnInit, AfterViewInit {
  private store = inject(Store);
  private route = inject(ActivatedRoute);
  private matDialog = inject(MatDialog);
  private router = inject(Router);

  registering$ = this.store.select(AuthState.registering);

  registerForm = new FormGroup(
    {
      contactName: new FormControl('', [Validators.required]),
      password: new FormControl(
        '',
        [Validators.required],
        [passwordValidator(() => firstValueFrom(this.email$))],
      ),
      passwordRepeat: new FormControl('', [Validators.required]),
      tos: new FormControl(false, [Validators.requiredTrue]),
      privacy: new FormControl(false, [Validators.requiredTrue]),
    },
    {
      validators: [matches('password', 'passwordRepeat')],
    },
  );

  hasRegistered = false;
  userAlreadyExistsSentNewInvite = false;

  email$ = this.route.queryParamMap.pipe(map((params) => params.get('email')));
  token$ = this.route.paramMap.pipe(map((params) => params.get('token')));

  authError$ = new BehaviorSubject(null);
  ngOnInit(): void {}

  ngAfterViewInit() {
    this.checkIfUserExistsForInvite();
  }

  async checkIfUserExistsForInvite() {
    const token = this.route.snapshot.paramMap.get('token');

    if (!token) {
      return;
    }

    this.store
      .dispatch(
        new CheckIfUserAlreadyExistsForInvite({
          inviteToken: token,
        }),
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.userAlreadyExistsSentNewInvite = true;
        },
        error: (err) => {
          console.error(err);

          if (
            err.status === 400 &&
            err.error?.message === 'ERRORS.EMPLOYEE_ALREADY_ASSIGNED'
          ) {
            this.hasRegistered = true;
          }
        },
      });
  }

  async register() {
    this.registerForm.markAsTouched();
    this.registerForm.markAsDirty();
    if (this.registerForm.invalid) return;

    const token = await firstValueFrom(this.token$);

    this.store
      .dispatch(
        new Register({
          contactName: this.registerForm.value.contactName as string,
          password: this.registerForm.value.password as string,
          inviteToken: token as string,
        }),
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.authError$.next(null);
          this.hasRegistered = true;
        },
        error: (err) => {
          this.authError$.next(err);
        },
      });
  }

  showDisclaimer() {
    window.open(externalUrls.disclaimerUrl, '_blank');
  }

  showPrivacyPolicy() {
    window.open(externalUrls.privacyPolicyUrl, '_blank');
  }
}
