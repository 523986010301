import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import type { TaxForm, TaxFormType } from '@prisma/client';
import { lastValueFrom } from 'rxjs';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from 'src/app/interfaces/base-state-model.interface';
import { mapCreatedUpdated } from 'src/app/interfaces/base.interface';
import { delayRequest } from 'src/app/shared/util/delayed-request.util';
import { toFormData } from 'src/app/shared/util/to-formdata';
import { environment } from 'src/environments/environment';

export class LoadTaxForms {
  static readonly type = '[TaxForms] Load Tax Forms';
}

export class UploadTaxForm {
  static readonly type = '[TaxForms] Upload Tax Form';
  constructor(
    public options: { date: Date; type: TaxFormType },
    public file: File,
  ) {}
}

export interface TaxFormsStateModel extends BaseStateModel {
  taxForms: any[];
  allowedTypes: TaxFormType[];
}

@State<TaxFormsStateModel>({
  name: 'taxForms',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    taxForms: [],
    allowedTypes: [],
  },
})
@Injectable()
export class TaxFormsState {
  private http = inject(HttpClient);

  @Selector()
  static error(state: TaxFormsStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: TaxFormsStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: TaxFormsStateModel) {
    return state.loaded;
  }

  @Selector()
  static allowedTypes(state: TaxFormsStateModel) {
    return state.allowedTypes;
  }

  @Selector()
  static taxForms(state: TaxFormsStateModel) {
    return state.taxForms;
  }

  @Selector()
  static groupedTaxForms(state: TaxFormsStateModel) {
    return state.allowedTypes.map((allowedType) => {
      return {
        type: allowedType,
        taxForms: state.taxForms.filter(
          (taxForm) => taxForm.type === allowedType,
        ),
      };
    });
  }

  @Action(LoadTaxForms)
  async loadTaxForms({ patchState }: StateContext<TaxFormsStateModel>) {
    patchState({ loading: true, error: null });
    try {
      const { taxForms, allowedTypes } = await lastValueFrom(
        this.http.get<{ taxForms: TaxForm[]; allowedTypes: TaxFormType[] }>(
          `${environment.apiUrl}/v1/tax-forms`,
        ),
      );

      const mappedTaxForms = taxForms
        .map((taxForm) => ({
          ...taxForm,
          date: new Date(taxForm.date),
          ...mapCreatedUpdated(taxForm),
        }))
        .sort((a, b) => b.date.getTime() - a.date.getTime());

      patchState({ taxForms: mappedTaxForms, allowedTypes });
    } catch (error) {
      patchState({ error });
    } finally {
      patchState({ loading: false, loaded: true });
    }
  }

  @Action(UploadTaxForm)
  async uploadTaxForm(
    ctx: StateContext<TaxFormsStateModel>,
    { options, file }: UploadTaxForm,
  ) {
    ctx.patchState({ loading: true });
    try {
      const formData = toFormData({
        ...options,
        file,
      });

      await delayRequest(
        lastValueFrom(
          this.http.post<TaxForm>(
            `${environment.apiUrl}/v1/tax-forms`,
            formData,
          ),
        ),
      );

      return ctx.dispatch(new LoadTaxForms());
    } catch (error) {
      throw error;
    } finally {
      ctx.patchState({ loading: false });
    }
  }
}
