import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-intro',
  template: `
    <div class="mb-8">
      <h2 class="text-xl font-bold">
        <ng-content select="[slot=title]"></ng-content>
      </h2>
      <div class="text-base leading-7 text-gray-600">
        <ng-content select="[slot=subtitle]"></ng-content>
      </div>
    </div>
  `,
})
export class LoginIntroComponent implements OnInit {
  ngOnInit(): void {}
}
