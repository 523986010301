import { DatePipe } from '@angular/common';
import { Component, inject, Injectable, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
  combineLatest,
  filter,
  lastValueFrom,
  map,
  switchMap,
  take,
} from 'rxjs';
import {
  AuthState,
  ImpersonateEmployee,
  LogOut,
  RefreshAuthTokens,
} from './auth/auth.state';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogOptions,
  DialogConfirmationButtonRole,
} from './shared/components/confirmation-dialog/confirmation-dialog.component';

@Injectable()
@Component({
  selector: 'app-root',
  template: `
    <div
      class="relative flex flex-col w-full h-full overflow-hidden print:overflow-visible sm:flex-row"
    >
      <!-- sidebar -->
      @if ((isAuthenticated$ | async) && (isNotLoginPage$ | async)) {
        <app-sidebar></app-sidebar>
      }

      <!-- app page -->
      <div
        class="relative z-0 flex-grow w-full overflow-auto print:overflow-visible"
        cdkScrollable
      >
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>

    <!-- switch account overlay -->
    @if (isSwitchingAccount$ | async) {
      <div
        class="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center bg-opacity-70 backdrop-blur-xl bg-slate-800"
      >
        <div class="p-4 bg-white rounded-lg shadow">
          <div class="font-bold">Switching account...</div>
          <div>Please wait while the page redirects</div>
        </div>
      </div>
    }
    <!-- impersonation banner -->
    @if (
      (isAuthenticated$ | async) &&
      (isImpersonating$ | async) &&
      (isNotLoginPage$ | async)
    ) {
      <div class="fixed bottom-0 left-0 right-0 z-50 print:hidden">
        <div
          class="flex items-center justify-between h-10 px-4 text-white bg-red-500"
        >
          <div class="flex items-center space-x-2">
            <div>You are impersonating an employee</div>
          </div>
          <button
            (click)="endImpersonation()"
            class="px-3 py-2 text-sm text-red-800 bg-red-100 rounded hover:bg-white"
          >
            End impersonation
          </button>
        </div>
      </div>
    }
  `,
  providers: [DatePipe],
})
export class AppComponent implements OnInit {
  private store = inject(Store);
  private translate = inject(TranslateService);
  private router = inject(Router);
  private toaster = inject(HotToastService);

  isAuthenticated$ = this.store.select(AuthState.isAuthenticated);
  isImpersonating$ = this.store.select(AuthState.isImpersonating);
  accessToken$ = this.store.select(AuthState.accessToken);

  private dialog = inject(MatDialog);
  private datePipe = inject(DatePipe);

  // get current page url from Angular router
  isNotLoginPage$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => this.router.url),
    map((url) => !url.startsWith('/auth')),
  );

  isSwitchingAccount$ = this.store.select(AuthState.isSwitchingAccount);

  constructor() {
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    combineLatest([
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
      ),
      this.isAuthenticated$,
    ]).subscribe(([event, authenticated]) => {
      if (!authenticated) return;
    });
  }

  ngOnInit() {
    // auth warning check
    this.checkForSessionExpiration();
    setInterval(() => {
      this.checkForSessionExpiration();
    }, 30000);
  }

  private checkForSessionExpiration() {
    const expires = this.store.selectSnapshot(AuthState.refreshTokenExpires);
    if (!expires) return;

    if (expires.getTime() < Date.now()) {
      console.log('Session expired. Logging out...');
      this.store.dispatch(new LogOut());
      return;
    }

    if (expires.getTime() - Date.now() < 5 * 60 * 1000) {
      this.showAboutToLogOutWarning();
    }
  }

  endImpersonation() {
    this.accessToken$
      .pipe(
        take(1),
        switchMap((accessToken) => {
          if (!accessToken?.originalEmployeeNumber) {
            throw new Error('Original employee number not defined');
          }
          return this.store.dispatch(
            new ImpersonateEmployee({
              employeeNumber: accessToken.originalEmployeeNumber,
              isSwitchingBack: true,
            }),
          );
        }),
        this.toaster.observe({
          loading: 'Ending impersonation session...',
          success: 'Reloading...',
          error: 'Failed to end impersonation. Please try again.',
        }),
      )
      .subscribe();
  }

  private showingAboutToLogOutWarning = false;
  async showAboutToLogOutWarning() {
    if (this.showingAboutToLogOutWarning) return;
    this.showingAboutToLogOutWarning = true;

    try {
      const expires = this.store.selectSnapshot(AuthState.refreshTokenExpires);

      const ref = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Your session is about to expire',
          body: `This is a warning that your session will expire within the next 5 minutes. You will be automatically logged out at ${this.datePipe.transform(
            expires,
            'medium',
          )}`,
          buttons: [
            {
              role: DialogConfirmationButtonRole.Cancel,
              text: 'Log out now',
              secondary: true,
            },
            {
              role: DialogConfirmationButtonRole.Accept,
              text: 'Extend session',
              primary: true,
            },
          ],
        } as ConfirmationDialogOptions,
      });

      const res = await lastValueFrom(ref.afterClosed());

      if (res !== DialogConfirmationButtonRole.Accept) {
        // log out now
        this.store.dispatch(new LogOut());
        return;
      }

      // extend session
      this.store.dispatch(new RefreshAuthTokens());
    } catch (err) {
      console.error(err);
    } finally {
      this.showingAboutToLogOutWarning = false;
    }
  }
}
