import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import type { Dependent } from '@prisma/client';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { delayRequest } from '../../shared/util/delayed-request.util';
import { ChangeRequest } from '../interfaces/change-request.interface';
import {
  DependentCreate,
  DependentUpdate,
} from '../interfaces/dependent.interface';
import { uploadSupportingDocumentation } from '../util/supporting-docs-upload';
import { UpsertChangeRequests } from './change-request.state';

export interface DependentStateModel extends BaseStateModel {
  dependents: Dependent[];
}

export class LoadDependents {
  static readonly type = '[Dependent] LoadDependents';
}

export class UpdateDependent {
  static readonly type = '[Dependent] UpdateDependent';
  constructor(
    public dependent: DependentUpdate,
    public files: File[] = [],
  ) {}
}

export class CreateDependent {
  static readonly type = '[Dependent] CreateDependent';
  constructor(
    public dependent: DependentCreate,
    public files: File[] = [],
  ) {}
}

export class DeleteDependent {
  static readonly type = '[Dependent] DeleteDependent';
  constructor(
    public dependentId: string,
    public files: File[] = [],
  ) {}
}

@State<DependentStateModel>({
  name: 'dependent',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    dependents: [],
  },
})
@Injectable()
export class DependentState {
  http = inject(HttpClient);

  @Selector()
  static loading(state: DependentStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: DependentStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: DependentStateModel) {
    return state.error;
  }

  @Selector()
  static dependents(state: DependentStateModel) {
    return state.dependents;
  }

  @Action(LoadDependents)
  async loadDependents(ctx: StateContext<DependentStateModel>) {
    ctx.patchState({ loading: true });
    try {
      const dependents = await lastValueFrom(
        this.http.get<Dependent[]>(`${environment.apiUrl}/v1/dependents`),
      );
      ctx.patchState({ dependents });
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(UpdateDependent)
  async updateDependent(
    ctx: StateContext<DependentStateModel>,
    action: UpdateDependent,
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await delayRequest(
        lastValueFrom(
          this.http.put<ChangeRequest>(
            `${environment.apiUrl}/v1/dependents/${action.dependent.id}`,
            action.dependent,
          ),
        ),
      );

      await uploadSupportingDocumentation(action, changeRequest, this.http);
      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(CreateDependent)
  async createDependent(
    ctx: StateContext<DependentStateModel>,
    action: CreateDependent,
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await delayRequest(
        lastValueFrom(
          this.http.post<ChangeRequest>(
            `${environment.apiUrl}/v1/dependents`,
            action.dependent,
          ),
        ),
      );

      await uploadSupportingDocumentation(action, changeRequest, this.http);

      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(DeleteDependent)
  async deleteDependent(
    ctx: StateContext<DependentStateModel>,
    action: DeleteDependent,
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await delayRequest(
        lastValueFrom(
          this.http.delete<ChangeRequest>(
            `${environment.apiUrl}/v1/dependents/${action.dependentId}`,
          ),
        ),
      );

      await uploadSupportingDocumentation(action, changeRequest, this.http);

      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }
}
