import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { of, switchMap } from 'rxjs';
import { ExchangeEncryptedTokenForJwt } from '../../auth.state';

@UntilDestroy()
@Component({
  selector: 'app-sso-callback-page',
  template: `
    <app-login-screen>
      <app-login-intro>
        <ng-container slot="title">Logging in...</ng-container>
        <ng-container slot="subtitle">
          You will be redirected shortly
        </ng-container>
      </app-login-intro>
    </app-login-screen>
  `,
  styles: [],
})
export class SsoCallbackPageComponent implements OnInit {
  route = inject(ActivatedRoute);
  store = inject(Store);
  toaster = inject(HotToastService);

  ngOnInit(): void {
    this.route.queryParamMap
      .pipe(
        untilDestroyed(this),
        switchMap((params) => {
          const token = params.get('token');
          if (!token) {
            return of();
          }
          return this.store.dispatch(
            new ExchangeEncryptedTokenForJwt({
              token: token,
            }),
          );
        }),
        this.toaster.observe({
          loading: 'Logging in...',
          success: 'Logged in',
          error: 'Failed to log in',
        }),
      )
      .subscribe();
  }
}
