import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Action, State, StateContext, createSelector } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthState } from '../../auth/auth.state';
import { GlobalDateRangeState } from '../../global-date-range.state';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import {
  mapCreatedUpdated,
  mostRecentUpdateOfCollection,
} from '../../interfaces/base.interface';
import { dataByPayPeriod } from '../../shared/util/data-by-pay-period.util';
import { isWithinPeriod } from '../../shared/util/is-within-period.util';
import {
  Compensation,
  PayComponentType,
} from '../interfaces/compensation.interface';

export class LoadCompensation {
  static readonly type = '[Compensation] Load';
}

export interface CompensationStateModel extends BaseStateModel {
  compensation: Compensation[];
  history: Compensation[];
}

function aggregateCompensation(compensation: Compensation[]) {
  const grouped = compensation.reduce(
    (acc, c) => {
      acc[c.payComponentCode] = acc[c.payComponentCode] || {
        amount: 0,
        payComponent: c.payComponent,
      };
      acc[c.payComponentCode].amount += c.amount;
      return acc;
    },
    {} as Record<string, Pick<Compensation, 'amount' | 'payComponent'>>,
  );

  return Object.values(grouped);
}

@State<CompensationStateModel>({
  name: 'compensation',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    compensation: [],
    history: [],
  },
})
@Injectable()
export class CompensationState {
  private http = inject(HttpClient);

  static getState = createSelector(
    [CompensationState],
    (state: CompensationStateModel) => state,
  );

  static compensation = createSelector(
    [CompensationState.getState],
    (state) => {
      return state.compensation.slice().sort((a, b) => {
        return a.payComponent.rank - b.payComponent.rank;
      });
    },
  );

  static loading = createSelector([CompensationState.getState], (state) => {
    return state.loading;
  });

  static loaded = createSelector([CompensationState.getState], (state) => {
    return state.loaded;
  });

  static error = createSelector([CompensationState.getState], (state) => {
    return state.error;
  });

  static history = createSelector([CompensationState.getState], (state) => {
    return state.history;
  });

  static historyByPayPeriod = createSelector(
    [CompensationState, AuthState.payPeriod],
    (state, payPeriod) => {
      if (!payPeriod) return [];
      return dataByPayPeriod(state.history, payPeriod);
    },
  );

  static selectionHistory = createSelector(
    [
      CompensationState,
      GlobalDateRangeState.startDate,
      GlobalDateRangeState.endDate,
    ],
    (state: CompensationStateModel, startDate, endDate) => {
      return state.history.filter((comp) => {
        return isWithinPeriod({
          eventStartDate: comp.paymentDate,
          eventEndDate: comp.paymentDate,
          periodStartDate: startDate,
          periodEndDate: endDate,
        });
      });
    },
  );

  static selectionHistoryTotal = createSelector(
    [CompensationState.selectionHistory],
    (history) => {
      return history.reduce((acc, curr) => acc + curr.amount, 0);
    },
  );

  static selectionHistoryFixed = createSelector(
    [CompensationState.selectionHistory],
    (history) => {
      return history.filter(
        (comp) =>
          comp.payComponent?.payComponentType === PayComponentType.FIXED,
      );
    },
  );

  static selectionHistoryFixedTotal = createSelector(
    [CompensationState.selectionHistoryFixed],
    (compensation) => {
      return compensation.reduce((acc, comp) => acc + comp.amount, 0);
    },
  );

  static selectionHistoryVariable = createSelector(
    [CompensationState.selectionHistory],
    (history) => {
      return history.filter(
        (comp) =>
          comp.payComponent?.payComponentType === PayComponentType.VARIABLE,
      );
    },
  );

  static selectionHistoryVariableTotal = createSelector(
    [CompensationState.selectionHistoryVariable],
    (compensation) => {
      return compensation.reduce((acc, comp) => acc + comp.amount, 0);
    },
  );

  static selectionHistoryBonus = createSelector(
    [CompensationState.selectionHistory],
    (history) => {
      return history.filter(
        (comp) =>
          comp.payComponent?.payComponentType === PayComponentType.BONUS,
      );
    },
  );

  static selectionHistoryBonusTotal = createSelector(
    [CompensationState.selectionHistoryBonus],
    (compensation) => {
      return compensation.reduce((acc, comp) => acc + comp.amount, 0);
    },
  );

  static selectionHistoryFixedAggregated = createSelector(
    [CompensationState.selectionHistoryFixed],
    (history) => {
      return aggregateCompensation(history);
    },
  );

  static selectionHistoryVariableAggregated = createSelector(
    [CompensationState.selectionHistoryVariable],
    (history) => {
      return aggregateCompensation(history);
    },
  );

  static selectionHistoryBonusAggregated = createSelector(
    [CompensationState.selectionHistoryBonus],
    (history) => {
      return aggregateCompensation(history);
    },
  );

  static lastUpdated = createSelector(
    [CompensationState.compensation],
    (compensation) => {
      return mostRecentUpdateOfCollection(compensation);
    },
  );

  @Action(LoadCompensation)
  async loadCompensation(ctx: StateContext<CompensationStateModel>) {
    try {
      ctx.patchState({
        loading: true,
        error: null,
      });

      const compensation = await lastValueFrom(
        this.http.get<any[]>(`${environment.apiUrl}/v1/compensation`),
      );
      const mappedCompensation: Compensation[] = compensation.map((comp) =>
        this.mapCompensation(comp),
      );
      ctx.patchState({
        compensation: mappedCompensation,
      });

      const compensationHistory = await lastValueFrom(
        this.http.get<any[]>(`${environment.apiUrl}/v1/compensation/history`),
      );
      const mappedCompensationHistory: Compensation[] = compensationHistory.map(
        (comp) => this.mapCompensation(comp),
      );
      const mergedCompensationHistory =
        this.mergeCompensationForTheSamePayComponentAndDate(
          mappedCompensationHistory,
        );

      ctx.patchState({
        history: mergedCompensationHistory,
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }

  private mapCompensation(comp: any): Compensation {
    return {
      ...comp,
      startDate: new Date(comp.startDate),
      endDate: new Date(comp.endDate),
      paymentDate: new Date(comp.paymentDate),
      ...mapCreatedUpdated(comp),
    };
  }

  /**
   * We may get multiple compensation records for the same pay component and date.
   * This method merges them into a single record by summing the amounts.
   * @param compensation
   * @returns
   */
  private mergeCompensationForTheSamePayComponentAndDate(
    compensation: Compensation[],
  ) {
    const groupedByPayComponentAndDate = compensation.reduce(
      (acc, c) => {
        const key = `${c.payComponentCode}-${c.paymentDate}`;
        if (acc[key]) {
          acc[key].push(c);
        } else {
          acc[key] = [c];
        }
        return acc;
      },
      {} as Record<string, Compensation[]>,
    );

    return Object.values(groupedByPayComponentAndDate)
      .map((payComponentComps) => {
        const [firstComp] = payComponentComps;
        return {
          ...firstComp,
          amount: payComponentComps.reduce((acc, c) => acc + c.amount, 0),
        };
      })
      .sort((a, b) => {
        return (
          (a.paymentDate ?? a.endDate).getTime() -
          (b.paymentDate ?? b.endDate).getTime()
        );
      });
  }
}
