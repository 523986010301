import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { externalUrls } from '../../../../environments/constants';
import { AuthState } from '../../auth.state';

@Component({
  selector: 'app-login-screen',
  template: `
    <div class="relative flex items-center w-full h-full overflow-hidden">
      <!-- left bg panel -->
      <div
        class="relative flex-col items-center justify-center flex-grow hidden w-1/2 h-full p-8 bg-left bg-cover sm:flex"
        style='background-image: url("assets/images/login-splash.jpg")'
      >
        <!-- gradient -->
        <div
          class="absolute top-0 bottom-0 left-0 right-0 z-0 opacity-80 bg-gradient-to-tr from-blue-900 to-red-300"
        ></div>

        <div class="relative z-10 w-full max-w-xl text-white drop-shadow">
          <!-- hero text -->
          <div class="text-4xl font-bold">
            Empowering Employee Rewards and Beyond Work-Life
          </div>
          <p class="mt-4 leading-7">
            An intuitive solution that removes organizational complexities
            related to data collection from multiple sources and consolidates it
            into one single, easy-to-use portal
          </p>
        </div>
      </div>

      <!-- right panel with login -->
      <div class="relative h-full overflow-y-auto bg-white sm:w-1/2">
        <div
          class="flex flex-col items-center justify-center w-full min-h-full p-8"
        >
          <!-- logos -->
          @if (logos) {
            <div
              class="flex items-center justify-center flex-shrink-0 w-full h-24 mb-16 space-x-8"
            >
              @if (details$ | async; as details) {
                <app-company-logo [companyId]="details.id"></app-company-logo>
              } @else {
                <img
                  src="assets/images/logo.svg"
                  alt="logo"
                  class="h-10 md:h-14"
                />
                <img
                  src="assets/images/logo-bwl.svg"
                  alt="logo"
                  class="h-10 md:h-14"
                />
              }
            </div>
          }

          <div class="flex-shrink-0 w-full max-w-md bg-white">
            <!-- content -->
            <ng-content></ng-content>
          </div>

          <!-- company-specific details -->
          @if (details$ | async; as details) {
            <div class="mt-20 text-sm text-center text-gray-700">
              @if (details.email) {
                <p>
                  <a href="mailto:{{ details.email }}" target="_blank">
                    {{ details.email }}
                  </a>
                </p>
              }
              @if (details.contactNumber) {
                <p>
                  <a href="tel:{{ details.contactNumber }}" target="_blank">
                    {{ details.contactNumber }}
                  </a>
                </p>
              }
              @if (details.website) {
                <p>
                  <a [href]="details.website" target="_blank" rel="nofollow">
                    {{ details.website }}
                  </a>
                </p>
              }
            </div>
          }

          <!-- copyright -->
          <p class="flex-shrink-0 mt-20 text-xs text-center text-gray-500">
            {{ 'copyright' | appConfig | async }}
            <br />
            <button (click)="showDisclaimer()" class="underline">
              View disclaimer
            </button>
          </p>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class LoginScreenComponent implements OnInit {
  private matDialog = inject(MatDialog);
  private http = inject(HttpClient);

  @Input() logos = true;
  loading = true;

  store = inject(Store);

  details$ = this.store.select(AuthState.publicCompanyDetails);

  ngOnInit(): void {}

  showDisclaimer() {
    window.open(externalUrls.disclaimerUrl, '_blank');
  }
}
