import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import type { ChangeRequestApprovalStatus, Employee } from '@prisma/client';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { mapCreatedUpdated } from '../../interfaces/base.interface';
import { ChangeRequest } from '../../manage-my-information/interfaces/change-request.interface';
import { delayRequest } from '../../shared/util/delayed-request.util';

export interface EmployeeChangeEntity {
  entity: any;
  change: ChangeRequest;
}

export interface EmployeeChanges {
  employee: Employee;
  entities: EmployeeChangeEntity[];
}

export interface ChangeRequestUpdate {
  requestId: string;
  approvalStatus: ChangeRequestApprovalStatus;
  approvalReason?: string;
  fieldApprovalReasons: {
    id: string;
    approvalReason?: string;
  }[];
}

export interface AdminChangeRequestsStateModel extends BaseStateModel {
  changeRequests: EmployeeChanges[];
}

export class LoadAdminChangeRequests {
  static readonly type = '[AdminChangeRequests] LoadAdminChangeRequests';
}

export class AdminUpdateChangeRequest {
  static readonly type = '[AdminChangeRequests] UpdateChange';
  constructor(public body: ChangeRequestUpdate) {}
}

@State<AdminChangeRequestsStateModel>({
  name: 'adminChangeRequests',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    changeRequests: [],
  },
})
@Injectable()
export class AdminChangeRequestsState {
  http = inject(HttpClient);

  @Selector()
  static loading(state: AdminChangeRequestsStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: AdminChangeRequestsStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: AdminChangeRequestsStateModel) {
    return state.error;
  }

  @Selector()
  static changeRequests(state: AdminChangeRequestsStateModel) {
    return state.changeRequests;
  }

  @Action(LoadAdminChangeRequests)
  async loadChangeRequests(ctx: StateContext<AdminChangeRequestsStateModel>) {
    ctx.patchState({ loading: true });
    try {
      const changeRequests = await lastValueFrom(
        this.http.get<EmployeeChanges[]>(
          `${environment.apiUrl}/v1/change-request`,
        ),
      );
      ctx.patchState({
        changeRequests: changeRequests.map((cr) => this.mapChangeRequest(cr)),
      });
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(AdminUpdateChangeRequest)
  async updateChangeRequest(
    ctx: StateContext<AdminChangeRequestsStateModel>,
    action: AdminUpdateChangeRequest,
  ) {
    ctx.patchState({ loading: true });
    try {
      await delayRequest(
        lastValueFrom(
          this.http.put(
            `${environment.apiUrl}/v1/change-request/${action.body.requestId}`,
            {
              approvalStatus: action.body.approvalStatus,
              approvalReason: action.body.approvalReason,
              fieldApprovalReasons: action.body.fieldApprovalReasons,
            },
          ),
        ),
      );

      return ctx.dispatch(new LoadAdminChangeRequests());
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  private mapChangeRequest(changeRequest: any) {
    return {
      ...changeRequest,
      entities: changeRequest.entities.map((entity: any) => ({
        ...entity,
        ...mapCreatedUpdated(changeRequest),
      })),
    } as EmployeeChanges;
  }
}
