import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout',
  template: `
    <app-login-screen>
      <p class="text-center">You are being signed out...</p>
    </app-login-screen>
  `,
})
export class LogoutComponent implements OnInit {
  ngOnInit(): void {}
}
