import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BehaviorSubject, catchError, map, of } from 'rxjs';
import { ConfirmEmailChange } from '../../auth.state';

@UntilDestroy()
@Component({
  selector: 'app-change-email',
  template: `
    <app-page>
      <ng-container slot="title">Change email</ng-container>
      <ng-container slot="body">
        @if ((loaded$ | async) === false) {
          <app-loader></app-loader>
        }
        @if (loaded$ | async) {
          @if (error$ | async) {
            <app-api-error [error]="error$ | async"></app-api-error>
          } @else {
            <div class="flex justify-center">
              <div class="max-w-md px-5 py-4 mt-20 bg-gray-100 rounded-lg">
                <p class="font-bold">Your email was successfully changed</p>
                <p>You will be redirected in 5 seconds...</p>
              </div>
            </div>
          }
        }
      </ng-container>
    </app-page>
  `,
})
export class ChangeEmailComponent implements OnInit {
  private store = inject(Store);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  error$ = new BehaviorSubject(null);
  loaded$ = new BehaviorSubject(false);

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        untilDestroyed(this),
        map((params) => params.get('token')),
      )
      .subscribe((token) => {
        if (!token) return;
        this.submitToken(token);
      });
  }

  private submitToken(token: string) {
    this.store
      .dispatch(new ConfirmEmailChange({ token }))
      .pipe(
        catchError((err) => {
          this.error$.next(err);
          this.loaded$.next(true);
          return of();
        }),
      )
      .subscribe(() => {
        this.loaded$.next(true);

        setTimeout(() => {
          this.router.navigate(['/']);
        }, 5000);
      });
  }
}
