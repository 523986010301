import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BehaviorSubject, firstValueFrom, map, tap } from 'rxjs';
import { matches } from '../../../shared/form-validators/matches.validator';
import { passwordValidator } from '../../../shared/form-validators/password.validator';
import { AuthState, ResetPassword } from '../../auth.state';

@UntilDestroy()
@Component({
  selector: 'app-reset-password',
  template: `
    <app-login-screen>
      @if (!hasResetPassword) {
        <form [formGroup]="resetForm" (ngSubmit)="resetPassword()">
          <div class="mb-6">
            <mat-form-field>
              <mat-label>{{ 'LOGIN.EMAIL' | translate }}</mat-label>
              <input type="text" disabled matInput [value]="email$ | async" />
            </mat-form-field>
          </div>
          <div class="mb-6">
            <mat-form-field>
              <mat-label>{{ 'LOGIN.PASSWORD' | translate }}</mat-label>
              <input
                type="password"
                matInput
                [formControl]="resetForm.controls['password']"
              />
              @if (resetForm.controls['password'].hasError('required')) {
                <mat-error>
                  {{ 'LOGIN.PASSWORD_REQUIRED' | translate }}
                </mat-error>
              }
            </mat-form-field>
            <app-password-strength
              [zxcvbn]="resetForm.controls['password'].getError('zxcvbn')"
              [password]="resetForm.controls['password'].value"
            ></app-password-strength>
          </div>
          <div class="mb-6">
            <mat-form-field>
              <mat-label>{{ 'LOGIN.CONFIRM_PASSWORD' | translate }}</mat-label>
              <input
                type="password"
                matInput
                [formControl]="resetForm.controls['passwordRepeat']"
              />
              @if (resetForm.controls['password'].hasError('required')) {
                <mat-error>
                  {{ 'LOGIN.PASSWORD_REQUIRED' | translate }}
                </mat-error>
              }
            </mat-form-field>
          </div>
          <app-api-error [error]="authError$ | async"></app-api-error>
          @if (
            resetForm.touched &&
            resetForm.dirty &&
            resetForm.hasError('matches')
          ) {
            <app-error>
              {{ 'LOGIN.PASSWORDS_DO_NOT_MATCH' | translate }}
            </app-error>
          }
          <div class="flex items-center justify-between">
            <a mat-button [routerLink]="'/auth/login'">
              {{ 'CANCEL' | translate }}
            </a>
            <button
              mat-flat-button
              color="primary"
              type="submit"
              [disabled]="resettingPassword$ | async"
            >
              <span>{{ 'LOGIN.SET_PASSWORD' | translate }}</span>
              @if (resettingPassword$ | async) {
                <app-loading-spinner></app-loading-spinner>
              }
            </button>
          </div>
        </form>
      }

      @if (hasResetPassword) {
        <p>
          {{ 'LOGIN.PASSWORD_RESET_SUCCESS' | translate }}
        </p>
        <div class="flex justify-center mt-4">
          <button mat-flat-button color="primary" [routerLink]="['/']">
            Return to login
          </button>
        </div>
      }
    </app-login-screen>
  `,
})
export class ResetPasswordComponent implements OnInit {
  private store = inject(Store);
  private route = inject(ActivatedRoute);

  authError$ = new BehaviorSubject(null);

  resettingPassword$ = this.store.select(AuthState.resettingPassword);

  hasResetPassword = false;

  email: string | null = null;
  email$ = this.route.queryParamMap.pipe(
    map((params) => params.get('email')),
    tap((email) => (this.email = email)),
  );
  token$ = this.route.paramMap.pipe(map((params) => params.get('token')));

  resetForm = new FormGroup(
    {
      password: new FormControl(
        '',
        [Validators.required],
        [passwordValidator(async () => firstValueFrom(this.email$))],
      ),
      passwordRepeat: new FormControl('', [Validators.required]),
    },
    {
      validators: [matches('password', 'passwordRepeat')],
    },
  );

  ngOnInit(): void {}

  async resetPassword() {
    this.resetForm.markAsTouched();
    this.resetForm.markAsDirty();
    if (!this.resetForm.valid) return;

    const token = await firstValueFrom(this.token$);

    this.store
      .dispatch(
        new ResetPassword({
          password: this.resetForm.value.password as string,
          token: token as string,
        }),
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.hasResetPassword = true;
          this.authError$.next(null);
        },
        error: (err) => {
          this.authError$.next(err);
        },
      });
  }
}
