import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BehaviorSubject, catchError, of } from 'rxjs';
import {
  AssumableEmployee,
  AssumeEmployee,
  AuthState,
  LoadAssociatedEmployees,
  LogOut,
} from '../../auth.state';

@UntilDestroy()
@Component({
  selector: 'app-tenant-selection-page',
  template: `
    <app-login-screen>
      @if (
        (loadedAssociatedEmployees$ | async) === false &&
        (loadingAssociatedEmployees$ | async) === true
      ) {
        <app-loader></app-loader>
      }

      @if ((loadedAssociatedEmployees$ | async) === false && (error$ | async)) {
        <app-get-started
          description="An error occurred while loading your accounts"
          buttonText="Try again"
          (takeAction)="refresh()"
        ></app-get-started>
      }

      @if (
        (loadedAssociatedEmployees$ | async) === true &&
        (loadingAssociatedEmployees$ | async) === false &&
        !(associatedEmployees$ | async)?.length
      ) {
        <app-login-intro>
          <ng-container slot="title">No linked accounts</ng-container>
          <ng-container slot="subtitle">
            <p>Your user is not linked to any accounts.</p>
            <p class="mt-4">
              Please contact your company administrator if you believe this is a
              mistake.
            </p>
          </ng-container>
        </app-login-intro>
      }

      @if (
        (loadedAssociatedEmployees$ | async) === true &&
        (associatedEmployees$ | async)?.length
      ) {
        <app-login-intro>
          <ng-container slot="title">Choose account</ng-container>
          <ng-container slot="subtitle">
            Your user is linked to multiple accounts
          </ng-container>
        </app-login-intro>
        <!-- accounts -->
        <div
          class="divide-y max-h-72 overflow-y-auto rounded shadow-inner bg-gray-50"
        >
          @for (employee of associatedEmployees$ | async; track employee) {
            <button
              matRipple
              (click)="assumeEmployee(employee)"
              class="flex flex-row items-center text-left hover:bg-gray-100 hover:underline px-8 py-1 w-full space-x-8 h-20"
            >
              <div
                class="flex items-center justify-center w-14 h-14 rounded-full"
              >
                <app-company-logo
                  [companyId]="employee.company.id"
                  [isBeyondWorkLife]="
                    employee.company.companyLogoType === 'BEYOND_WORK_LIFE'
                  "
                ></app-company-logo>
              </div>
              <div class="flex flex-col text-gray-900">
                <b>{{ employee.company.name }}</b>
                <span>
                  {{ employee.firstName }} {{ employee.lastName }} ·
                  <span class="text-gray-500">
                    {{ 'ROLES.' + employee.role | translate }}
                  </span>
                </span>
              </div>
            </button>
          }
        </div>
      }

      <!-- footer -->
      <div class="flex items-center justify-center p-4 mt-8">
        <!-- sign out -->
        <button mat-flat-button (click)="logOut()" aria-label="Sign out">
          <mat-icon>logout</mat-icon>
          <span class="ml-2">Sign out</span>
        </button>
      </div>
    </app-login-screen>
  `,
  styles: [],
})
export class TenantSelectionPageComponent implements OnInit {
  store = inject(Store);
  route = inject(ActivatedRoute);
  toaster = inject(HotToastService);

  associatedEmployees$ = this.store.select(AuthState.associatedEmployees);
  loadedAssociatedEmployees$ = this.store.select(
    AuthState.loadedAssociatedEmployees,
  );
  loadingAssociatedEmployees$ = this.store.select(
    AuthState.loadingAssociatedEmployees,
  );

  error$ = new BehaviorSubject<string | null>(null);

  refresh() {
    this.store.dispatch(new LoadAssociatedEmployees());
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadAssociatedEmployees());

    // automatically assume employee if there is only one
    this.associatedEmployees$
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          this.error$.next(err);
          return of();
        }),
      )
      .subscribe((employees) => {
        if (!employees) {
          return;
        }
        if (employees.length === 1) {
          this.assumeEmployee(employees[0]);
        }
      });
  }

  assumeEmployee(employee: AssumableEmployee) {
    this.store
      .dispatch(
        new AssumeEmployee({
          employeeId: employee.id,
          companyId: employee.companyId,
          navigationUrl: this.route.snapshot.queryParams['redirectUrl'] || null,
        }),
      )
      .pipe(
        catchError((err) => {
          this.error$.next(err);
          return of();
        }),
        this.toaster.observe({
          loading: 'Logging in...',
          success: 'Logged in',
          error: 'Failed to log in',
        }),
      );
  }

  logOut() {
    this.store.dispatch(new LogOut());
  }
}
