import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BaseStateModel,
  BASE_STATE_DEFAULTS,
} from '../../interfaces/base-state-model.interface';
import { Overview } from '../interfaces/overview.interface';

export class LoadOverview {
  static readonly type = '[Overview] Load overview';
}

export interface OverviewStateModel extends BaseStateModel {
  overview: Overview;
}

@State<OverviewStateModel>({
  name: 'overview',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    overview: {
      benefit: 0,
      workLifeBenefit: 0,
      compensation: 0,
      paidTimeOff: 0,
    },
  },
})
@Injectable()
export class OverviewState {
  private http = inject(HttpClient);

  @Selector()
  static overview(state: OverviewStateModel) {
    return state.overview;
  }

  @Selector()
  static total(state: OverviewStateModel) {
    const overviewData = state.overview;

    return (
      overviewData.compensation +
      overviewData.benefit +
      overviewData.workLifeBenefit +
      overviewData.paidTimeOff
    );
  }

  @Selector()
  static loading(state: OverviewStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: OverviewStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: OverviewStateModel) {
    return state.error;
  }

  @Action(LoadOverview)
  async loadOverview(ctx: StateContext<OverviewStateModel>) {
    ctx.patchState({
      loading: true,
      error: null,
    });

    try {
      const overview = await lastValueFrom(
        this.http.get<Overview>(`${environment.apiUrl}/v1/overview`),
      );

      ctx.patchState({
        overview: {
          compensation: overview.compensation || 0,
          benefit: overview.benefit || 0,
          workLifeBenefit: overview.workLifeBenefit || 0,
          paidTimeOff: overview.paidTimeOff || 0,
        },
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }
}
