import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from '../auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  private store = inject(Store);
  private router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isAuthenticated = this.store.selectSnapshot(
      AuthState.isAuthenticated,
    );

    if (!isAuthenticated) {
      this.router.navigate(['/auth/login'], {
        queryParams: {
          redirectUrl: state.url,
        },
      });
      return false;
    }

    // check the user's role
    const accessToken = this.store.selectSnapshot(AuthState.accessToken);

    // if the user is logged in but has not yet assumed a role, navigate them to the tenant selection screen
    if (
      !accessToken?.employeeId &&
      !state.url.startsWith('/auth/tenant-selection')
    ) {
      this.router.navigate(['/auth/tenant-selection']);
      return false;
    }

    if (
      route.data['roles'] &&
      !route.data['roles'].includes(accessToken!.role)
    ) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
}
