import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import type { ChangeEntityName, ChangeRequestFile } from '@prisma/client';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { mapCreatedUpdated } from '../../interfaces/base.interface';
import { DownloadService } from '../../shared/services/download.service';
import { ChangeRequest } from '../interfaces/change-request.interface';

export interface ChangeRequestStateModel extends BaseStateModel {
  changeRequests: ChangeRequest[];
}

export class LoadChangeRequests {
  static readonly type = '[ChangeRequest] LoadChangeRequests';
}

export class DismissChangeRequest {
  static readonly type = '[ChangeRequest] DismissChangeRequest';
  constructor(public requestId: string | null) {}
}

export class UpsertChangeRequests {
  static readonly type = '[ChangeRequest] UpsertChangeRequests';
  constructor(public changeRequests: ChangeRequest[]) {}
}

export class ViewSupportingDocument {
  static readonly type = '[ChangeRequest] ViewSupportingDocument';
  constructor(public file: ChangeRequestFile) {}
}

export class DownloadSupportingDocument {
  static readonly type = '[ChangeRequest] DownloadSupportingDocument';
  constructor(public file: ChangeRequestFile) {}
}

@Injectable()
@State<ChangeRequestStateModel>({
  name: 'changeRequest',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    changeRequests: [],
  },
})
export class ChangeRequestState {
  http = inject(HttpClient);
  downloader = inject(DownloadService);

  @Selector()
  static loading(state: ChangeRequestStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: ChangeRequestStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: ChangeRequestStateModel) {
    return state.error;
  }

  @Selector()
  static changeRequests(state: ChangeRequestStateModel) {
    return state.changeRequests.slice().sort((a, b) => {
      return a.created > b.created ? -1 : 1;
    });
  }

  static getEntityChanges(entityType: ChangeEntityName, entityId = '') {
    if (entityId === '') return this.changeRequests;

    return createSelector(
      [ChangeRequestState],
      (state: ChangeRequestStateModel) => {
        const t = state.changeRequests.filter(
          (changeRequest) =>
            changeRequest.type === 'UPDATE' &&
            changeRequest.entityName === entityType &&
            changeRequest.entityId === entityId,
        );
        return t;
      },
    );
  }

  @Action(DismissChangeRequest)
  async dismissRejectedChangeRequest(
    ctx: StateContext<ChangeRequestStateModel>,
    payload: DismissChangeRequest,
  ) {
    ctx.patchState({ loading: true });
    try {
      await lastValueFrom(
        this.http.put(
          `${environment.apiUrl}/v1/change-request/cancel/${payload.requestId}`,
          null,
        ),
      );

      return ctx.dispatch(new LoadChangeRequests());
    } catch (err) {
      ctx.patchState({ error: err });
      throw err;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(LoadChangeRequests)
  async loadChangeRequests(ctx: StateContext<ChangeRequestStateModel>) {
    ctx.patchState({ loading: true });
    try {
      const changeRequests = await lastValueFrom(
        this.http.get<ChangeRequest[]>(
          `${environment.apiUrl}/v1/change-request/my`,
        ),
      );
      ctx.patchState({
        changeRequests: changeRequests.map((cr) => this.mapChangeRequest(cr)),
      });
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(UpsertChangeRequests)
  async upsertChangeRequests(
    ctx: StateContext<ChangeRequestStateModel>,
    action: UpsertChangeRequests,
  ) {
    ctx.patchState({
      changeRequests: ctx
        .getState()
        .changeRequests.filter(
          (changeRequest) =>
            !action.changeRequests.find((cr) => cr.id === changeRequest.id),
        )
        .concat(action.changeRequests),
    });

    return ctx.dispatch(new LoadChangeRequests());
  }

  @Action(ViewSupportingDocument)
  async viewChangeRequestFile(
    ctx: StateContext<ChangeRequestStateModel>,
    action: ViewSupportingDocument,
  ) {
    const url = await this.getFileSignedUrl(action.file);

    window.open(url, '_blank');
  }

  @Action(DownloadSupportingDocument)
  async downloadChangeRequestFile(
    ctx: StateContext<ChangeRequestStateModel>,
    action: DownloadSupportingDocument,
  ) {
    const url = await this.getFileSignedUrl(action.file);

    this.downloader.download(url, action.file.fileName ?? 'download.pdf');
  }

  private async getFileSignedUrl(file: ChangeRequestFile) {
    const { url } = await lastValueFrom(
      this.http.get<{ url: string }>(
        `${environment.apiUrl}/v1/change-request/${file.requestId}/documents/${file.id}`,
      ),
    );

    return url;
  }

  private mapChangeRequest(changeRequest: any) {
    return {
      ...changeRequest,
      ...mapCreatedUpdated(changeRequest),
      fields: changeRequest.fields.map((field: any) => ({
        ...field,
        ...mapCreatedUpdated(field),
      })),
    } as ChangeRequest;
  }
}
