import { inject, Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, of } from 'rxjs';
import {
  LoadAssociatedEmployees,
  LoadUserDetails,
} from '../../auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsResolverService implements Resolve<any> {
  private store = inject(Store);

  resolve() {
    return this.store
      .dispatch([new LoadUserDetails(), new LoadAssociatedEmployees()])
      .pipe(catchError(() => of(null)));
  }
}
