import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { filter, map, switchMap, take } from 'rxjs';
import { EpiUseSupportAssumeEmployee } from '../../auth.state';

@UntilDestroy()
@Component({
  selector: 'app-epi-use-support-assume-employee',
  template: `
    <app-login-screen>
      <div>
        Impersonate employee {{ employeeName$ | async }} ({{
          employeeNumber$ | async
        }}) for company {{ companyName$ | async }} ({{ companyId$ | async }})
      </div>
      <form [formGroup]="form" (ngSubmit)="assume()">
        <mat-form-field>
          <mat-label>Enter EPI-USE Support password</mat-label>
          <input
            matInput
            type="password"
            [formControl]="form.controls['password']"
            placeholder="E.g., 12345678"
          />
        </mat-form-field>
        <button
          type="submit"
          mat-flat-button
          color="primary"
          (click)="assume()"
        >
          Impersonate
        </button>
      </form>
    </app-login-screen>
  `,
  styles: [],
})
export class EpiUseSupportAssumeEmployeeComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private store = inject(Store);
  private formBuilder = inject(FormBuilder);

  form = this.formBuilder.group({
    password: new FormControl('', [Validators.required]),
  });

  token$ = this.route.paramMap.pipe(map((params) => params.get('token')));
  companyName$ = this.route.queryParamMap.pipe(
    map((params) => params.get('companyName')),
  );
  companyId$ = this.route.queryParamMap.pipe(
    map((params) => params.get('companyId')),
  );
  employeeName$ = this.route.queryParamMap.pipe(
    map((params) => params.get('employeeName')),
  );
  employeeNumber$ = this.route.queryParamMap.pipe(
    map((params) => params.get('employeeNumber')),
  );

  ngOnInit(): void {}

  assume() {
    if (!this.form.valid) return;
    const password = this.form.getRawValue().password;
    if (!password) return;

    this.token$
      .pipe(
        untilDestroyed(this),
        filter((token): token is string => token !== null),
        take(1),
        switchMap((token: string) =>
          this.store.dispatch(new EpiUseSupportAssumeEmployee(token, password)),
        ),
      )
      .subscribe();
  }
}
