import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '../../auth/auth.state';
import { CompanyFeature } from '../../auth/interfaces/company-feature.enum';
import { AppRoute, NEWS, OVERVIEW, WELCOME } from '../../routes';

export type FeatureRedirects = Partial<Record<CompanyFeature, any[]>>;

/**
 * Homepage redirect based on the feature flags the user has access to
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagRedirectGuard implements CanActivate {
  private store = inject(Store);
  private router = inject(Router);

  private readonly preferredRouteOrder: AppRoute[] = [OVERVIEW, WELCOME, NEWS];

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    const allowedFeatures = this.store.selectSnapshot(AuthState.features) ?? [];
    const userRole = this.store.selectSnapshot(AuthState.role);

    // find the first route in the preferred route order that the user is allowed to access
    const firstAllowedRoute = this.preferredRouteOrder.find((route) => {
      const featuresRequiredToAccessRoute = route.requiredFeatures || [];
      const rolesAllowedToAccessRoute = route.roles || [];

      const hasRequiredFeature = featuresRequiredToAccessRoute.some((feature) =>
        allowedFeatures.includes(feature),
      );
      const hasRequiredRole = rolesAllowedToAccessRoute.some(
        (role) => role === userRole,
      );

      return hasRequiredFeature && hasRequiredRole;
    });

    if (!firstAllowedRoute) return true;

    this.router.navigate([firstAllowedRoute.path]);
    return true;
  }
}
