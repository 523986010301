import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  Action,
  Actions,
  Selector,
  State,
  StateContext,
  ofAction,
} from '@ngxs/store';
import type { Employee, UserLog } from '@prisma/client';
import { catchError, finalize, takeUntil, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import {
  PaginationQuery,
  PaginationResponse,
  sanitizePaginationQuery,
} from '../../interfaces/pagination.interface';

export class LoadUserLogs {
  static readonly type = '[UserLog] Load User Logs';
  constructor(public pagination?: PaginationQuery) {}
}

export interface UserLogsStateModel extends BaseStateModel {
  logs: (UserLog & {
    employee: Pick<Employee, 'employeeNumber'>;
  })[];
  pagingMeta?: PaginationResponse<UserLog[]>['meta'];
}

@State<UserLogsStateModel>({
  name: 'userLog',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    logs: [],
    pagingMeta: undefined,
  },
})
@Injectable()
export class UserLogsState {
  http = inject(HttpClient);
  actions$ = inject(Actions);

  @Selector()
  static logs(state: UserLogsStateModel) {
    return state.logs;
  }

  @Selector()
  static pagingMeta(state: UserLogsStateModel) {
    return state.pagingMeta;
  }

  @Selector()
  static error(state: UserLogsStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: UserLogsStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: UserLogsStateModel) {
    return state.loaded;
  }

  @Action(LoadUserLogs, {
    cancelUncompleted: true,
  })
  async loadUserLogs(
    ctx: StateContext<UserLogsStateModel>,
    action: LoadUserLogs,
  ) {
    ctx.patchState({
      loading: true,
      error: null,
    });

    return this.http
      .get<PaginationResponse<UserLogsStateModel['logs']>>(
        `${environment.apiUrl}/v1/user-logs`,
        {
          params:
            action.pagination && sanitizePaginationQuery(action.pagination),
        },
      )
      .pipe(
        takeUntil(this.actions$.pipe(ofAction(LoadUserLogs))),
        catchError((err) => {
          ctx.patchState({
            error: err,
          });
          return throwError(() => err);
        }),
        tap((res) => {
          ctx.patchState({
            logs: res.data,
            pagingMeta: res.meta,
          });
        }),
        finalize(() => {
          ctx.patchState({
            loading: false,
            loaded: true,
          });
        }),
      );
  }
}
