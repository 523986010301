import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { AuthState, ForgotPassword } from '../../auth.state';

@UntilDestroy()
@Component({
  selector: 'app-forgot',
  template: `
    <app-login-screen>
      @if (!hasSentResetLink) {
        <form [formGroup]="resetForm" (ngSubmit)="resetPassword()">
          <app-login-intro>
            <ng-container slot="title">Forgot password?</ng-container>
            <ng-container slot="subtitle">
              {{ 'LOGIN.RESET_INSTRUCTIONS' | translate }}
            </ng-container>
          </app-login-intro>
          <div class="mb-6">
            <mat-form-field>
              <mat-label>{{ 'LOGIN.EMAIL' | translate }}</mat-label>
              <input
                type="email"
                matInput
                [formControl]="resetForm.controls['email']"
                [placeholder]="'LOGIN.EMAIL_PLACEHOLDER' | translate"
              />
              @if (resetForm.controls['email'].hasError('email')) {
                <mat-error>
                  {{ 'ERRORS.INVALID_EMAIL' | translate }}
                </mat-error>
              }
            </mat-form-field>
          </div>
          <app-api-error [error]="authError$ | async"></app-api-error>
          @if (
            resetForm.touched &&
            resetForm.dirty &&
            resetForm.hasError('atLeastOne')
          ) {
            <app-error>
              {{ 'ERRORS.AT_LEAST_ONE' | translate }}
            </app-error>
          }
          <div class="flex items-center justify-between">
            <a mat-button [routerLink]="'/auth/login'">
              {{ 'CANCEL' | translate }}
            </a>
            <button
              mat-flat-button
              color="primary"
              type="submit"
              [disabled]="sendingResetLink$ | async"
            >
              <span>
                {{ 'LOGIN.SEND_RESET_LINK' | translate }}
              </span>
              @if (sendingResetLink$ | async) {
                <app-loading-spinner></app-loading-spinner>
              }
            </button>
          </div>
        </form>
      }

      @if (hasSentResetLink) {
        <p>
          {{ 'LOGIN.RESET_LINK_SENT' | translate }}
        </p>
      }
    </app-login-screen>
  `,
})
export class ForgotComponent implements OnInit {
  private store = inject(Store);
  private fb = inject(FormBuilder);

  authError$ = new BehaviorSubject(null);

  sendingResetLink$ = this.store.select(AuthState.sendingResetLink);

  resetForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  hasSentResetLink = false;

  ngOnInit(): void {}

  resetPassword() {
    this.resetForm.markAsDirty();
    this.resetForm.markAsTouched();
    if (!this.resetForm.valid) return;

    this.authError$.next(null);

    this.store
      .dispatch(
        new ForgotPassword({
          email: this.resetForm.controls['email'].value,
        }),
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.hasSentResetLink = true;
        },
        error: (err) => {
          this.authError$.next(err);
        },
      });
  }
}
